import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Divider,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
} from "@mui/material";
import { EmpDataStore, EmployeesStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { useEffect, useState, useRef } from "react";
import { GridLoader } from "../../../Common/Loaders";
import axios from "axios";

export default function RequestLeave() {
  const EmpData = EmployeesStore((state) => state.EmpData);
  const RequestDialog = EmpDataStore((state) => state.RequestDialog);
  const SetRequestDialog = EmpDataStore((state) => state.SetRequestDialog);
  const SetSnackMessage = EmpDataStore((state) => state.SetSnackMessage);
  const SetSnackBarType = EmpDataStore((state) => state.SetSnackBarType);
  const SetLoadingShow = EmpDataStore((state) => state.SetLoadingShow);
  const SetSnackBarShow = EmpDataStore((state) => state.SetSnackBarShow);
  const SetLeaveTaken = EmpDataStore((state) => state.SetLeaveTaken);
  const SetLeaveList = EmpDataStore((state) => state.SetLeaveList);
  const LeaveBalances = EmpDataStore((state) => state.LeaveBalances);
  const [ShowDiaCont, SetShowDiaCont] = useState(false);
  const [EdDis, SetEdDis] = useState(false);
  const [RPTDis, SetRPTDis] = useState(true);
  const [FormStep, SetFormStep] = useState(0);
  const [LTSel, SETLTSel] = useState("");
  const [FileHelperText, SetFileHelperText] = useState("");
  const [ED, SetED] = useState("");
  const [TOTDays, SetTOTDays] = useState(0);
  const [hasRPT, SethasRPT] = useState(0);
  const [ShowNote, SetShowNote] = useState(false);
  const [NoteText, SetNoteText] = useState("");
  const [NoteType, SetNoteType] = useState("warning");
  const [RPTFILE, SetRPTFILE] = useState("");
  const [Uploading, SetUploading] = useState(false);
  const [UploadResp, SetUploadResp] = useState("");
  const startDateF = useRef(null);
  const endDateF = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      SetShowDiaCont(true);
    }, 3000);
  }, []);
  const CloseDialog = () => {
    SetFormStep(0);
    SetRequestDialog();
    SETLTSel("");
    SetUploading(false);
  };
  const ChangeType = (event) => {
    SetShowNote(false);
    if (FormStep != 0) {
      startDateF.current.value = null;
      endDateF.current.value = null;
    }
    SETLTSel(event.target.value);
    if (event.target.value === "Uncertified Sick Leave") {
      SetEdDis(false);
      SetRPTDis(true);
      SethasRPT(0);
      SetFileHelperText("");
    } else if (event.target.value === "Uncertified Sick Leave 1/2 Day") {
      SetTOTDays(0.5);
      SetEdDis(true);
      SetRPTDis(true);
      SethasRPT(0);
      SetFileHelperText("");
    } else if (event.target.value === "Sick Leave With Report") {
      SetEdDis(false);
      SetRPTDis(false);
      SethasRPT(1);
      SetFileHelperText("Please Upload Your Medical Report");
    } else if (event.target.value === "Maternity Leave") {
      SetTOTDays(62);
      SetEdDis(true);
      SetRPTDis(true);
      SethasRPT(0);
      SetFileHelperText("");
    } else if (event.target.value === "Annual Leave") {
      SetEdDis(false);
      SetRPTDis(true);
      SethasRPT(0);
      SetFileHelperText("");
    } else if (event.target.value === "Annual Leave 1/2 Day") {
      SetTOTDays(0.5);
      SetEdDis(true);
      SetRPTDis(true);
      SethasRPT(0);
      SetFileHelperText("");
    } else if (event.target.value === "R and R Leave") {
      SetTOTDays(5);
      SetEdDis(true);
      SetRPTDis(true);
      SethasRPT(0);
      SetFileHelperText("");
    }
    SetFormStep(1);
  };
  const ChangeStartDate = (event) => {
    if (!EdDis) {
    } else {
      if (
        LTSel === "Annual Leave 1/2 Day" ||
        LTSel === "Uncertified Sick Leave 1/2 Day"
      ) {
        SetED(event.target.value);
      } else if (LTSel === "Maternity Leave") {
        FindED2(event.target.value, 62);
      } else if (LTSel === "R and R Leave") {
        FindED(event.target.value, 5);
      }
      SetFormStep(2);
    }
  };
  const ChangeEndDate = (event) => {
    const ANBAL = LeaveBalances[0].normalleavebalance;
    const SIBAL = LeaveBalances[0].sickleavebalance;
    const ACBAL = LeaveBalances[0].previeusbalance;
    const TOTBAL = ANBAL + ACBAL;
    const NoDays = CalcDays();
    if (LTSel === "Uncertified Sick Leave" && NoDays > 3) {
      SetNoteText("Uncertified Sick Leave Can Not Be More Than 3 Working Days");
      SetNoteType("error");
      SetShowNote(true);
      SetFormStep(1);
    } else if (LTSel === "Uncertified Sick Leave" && NoDays > SIBAL) {
      SetNoteText(
        "You Have Exceeded Your Sick Leave Balance Please Provide Your Medical Report"
      );
      SetNoteType("warning");
      SetShowNote(true);
      SetFormStep(2);
      SetRPTDis(false);
      SethasRPT(1);
      SetFileHelperText("Please Upload Your Medical Report");
    } else if (LTSel === "Annual Leave" && NoDays > TOTBAL) {
      SetNoteText(
        "You Have Exceeded Your Annual Leave Balance Please Provide Your Approval"
      );
      SetNoteType("warning");
      SetShowNote(true);
      SetFormStep(2);
      SetRPTDis(false);
      SethasRPT(1);
      SetFileHelperText("Please Upload Your Approval Report");
    } else {
      SetShowNote(false);
      SetFormStep(2);
    }
    SetED(event.target.value);
    SetTOTDays(NoDays);
  };
  const FindED = (SD, ND) => {
    let count = 0;
    let ENDDATE;
    const startDate = new Date(SD.replace(/-/g, "/"));
    while (count < ND) {
      ENDDATE = new Date(startDate.setDate(startDate.getDate() + 1));
      if (ENDDATE.getDay() != 5 && ENDDATE.getDay() != 6) {
        count++;
      }
    }
    const getYear = ENDDATE.toLocaleString("default", { year: "numeric" });
    const getMonth = ENDDATE.toLocaleString("default", { month: "2-digit" });
    const getDay = ENDDATE.toLocaleString("default", { day: "2-digit" });
    const dateFormat = getYear + "-" + getMonth + "-" + getDay;
    SetED(dateFormat);
  };
  const FindED2 = (SD, ND) => {
    let count = 0;
    let ENDDATE;
    const startDate = new Date(SD.replace(/-/g, "/"));
    while (count < ND) {
      ENDDATE = new Date(startDate.setDate(startDate.getDate() + 1));
      count++;
    }
    const getYear = ENDDATE.toLocaleString("default", { year: "numeric" });
    const getMonth = ENDDATE.toLocaleString("default", { month: "2-digit" });
    const getDay = ENDDATE.toLocaleString("default", { day: "2-digit" });
    const dateFormat = getYear + "-" + getMonth + "-" + getDay;
    SetED(dateFormat);
  };
  const CalcDays = () => {
    var startDate = new Date(startDateF.current.value);
    var endDate = new Date(endDateF.current.value);
    let count = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 5 && dayOfWeek !== 6) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    let withHoso;
    if (data.get("hose") === "") {
      withHoso = 0;
    } else {
      withHoso = 1;
    }
    fetch(APIURL + "employee/leave/requestleave.php", {
      method: "post",
      body: JSON.stringify({
        soc: data.get("soc"),
        fn: data.get("fullname"),
        svn: data.get("svn"),
        sve: data.get("sve"),
        withhoso: withHoso,
        hoson: data.get("hoson"),
        hosoe: data.get("hose"),
        sd: data.get("sd"),
        ed: ED,
        lt: LTSel,
        hasreport: hasRPT,
        rpt: RPTFILE,
        reas: data.get("reas"),
        dc: TOTDays,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.content);
          if (item.id === 1) {
            SetSnackBarType("success");
            setTimeout(() => {
              SetSnackBarShow();
              fetch(APIURL + "employee/leave/takenleaves.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetLeaveTaken(result);
                });
              fetch(APIURL + "employee/leave/listleaves.php", {
                method: "post",
                body: JSON.stringify({
                  soc: EmpData[0].ref,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Request-Headers":
                    "Content-Type, Authorization",
                  "Access-Control-Allow-Credentials": "true",
                },
              })
                .then((res) => res.json())
                .then((result) => {
                  SetLeaveList(result);
                  SetLoadingShow();
                  CloseDialog();
                });
            }, 2000);
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetSnackBarShow();
              SetLoadingShow();
              CloseDialog();
            }, 2000);
          }
        });
      });
  };
  const startUpload = (event) => {
    SetUploading(true);
    SetUploadResp("Uploading File ...");
    const formData = new FormData();
    formData.append("cv", event.target.files[0]);
    axios
      .post(APIURL + "employee/leave/uploadleave.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(function (response) {
        response.data.forEach((item) => {
          if (item.id === 1) {
            SetRPTFILE(item.message);
            SetUploadResp("File Uploaded Successfully");
            setTimeout(() => {
              SetUploading(false);
            }, 2000);
          } else {
            SetUploadResp(item.message);
            setTimeout(() => {
              SetUploading(false);
            }, 2000);
          }
        });
      });
  };
  return (
    <Dialog open={RequestDialog} onClose={CloseDialog} maxWidth="xl" fullWidth>
      <DialogTitle color={"#2f3193"}>Request Leave</DialogTitle>
      <Divider />
      <DialogContent sx={{ pb: 1 }}>
        {ShowDiaCont ? (
          <Box component="form" onSubmit={sendRequest}>
            {EmpData.map((e) => (
              <Grid key={e.lid} container spacing={3}>
                <div className="d-none">
                  <TextField id="soc" name="soc" defaultValue={e.ref} />
                  <TextField
                    id="fullname"
                    name="fullname"
                    defaultValue={e.fullname}
                  />
                </div>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography variant="h6" color={"#850000"}>
                    Select Leave Type
                  </Typography>
                  <Box>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={LTSel}
                      onChange={ChangeType}
                    >
                      <FormControlLabel
                        value="Annual Leave"
                        control={<Radio />}
                        label="Annual Leave"
                      />
                      <FormControlLabel
                        value="Annual Leave 1/2 Day"
                        control={<Radio />}
                        label="Annual Leave 1/2 Day"
                      />
                      <FormControlLabel
                        value="Uncertified Sick Leave"
                        control={<Radio />}
                        label="Uncertified Sick Leave"
                      />
                      <FormControlLabel
                        value="Uncertified Sick Leave 1/2 Day"
                        control={<Radio />}
                        label="Uncertified Sick Leave 1/2 Day"
                      />
                      <FormControlLabel
                        value="Sick Leave With Report"
                        control={<Radio />}
                        label="Sick Leave With Report"
                      />
                      {e.gender === "Female" && (
                        <FormControlLabel
                          value="Maternity Leave"
                          control={<Radio />}
                          label="Maternity Leave"
                        />
                      )}
                      {e.contract === 6 && (
                        <FormControlLabel
                          value="R and R Leave"
                          control={<Radio />}
                          label="R and R Leave"
                        />
                      )}
                    </RadioGroup>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  {FormStep != 0 && (
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            size="small"
                            id="sd"
                            helperText="Set Leave Starting Date"
                            name="sd"
                            autoComplete="sd"
                            type="date"
                            inputRef={startDateF}
                            onChange={ChangeStartDate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            size="small"
                            id="ed"
                            helperText="Set Leave Ending Date"
                            name="ed"
                            disabled={EdDis}
                            autoComplete="ed"
                            type="date"
                            inputRef={endDateF}
                            onChange={ChangeEndDate}
                          />
                        </Grid>
                      </Grid>
                      {ShowNote && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Alert
                            severity={NoteType}
                            variant="outlined"
                            sx={{ textAlign: "center" }}
                          >
                            {NoteText}
                          </Alert>
                        </Grid>
                      )}
                    </Box>
                  )}
                  {FormStep === 2 && (
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            size="small"
                            id="svn"
                            label="Supervisor Name"
                            name="svn"
                            autoComplete="svn"
                            defaultValue={e.supervisor}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            size="small"
                            id="sve"
                            label="Supervisor Email"
                            name="sve"
                            autoComplete="sve"
                            defaultValue={e.svemail}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            size="small"
                            id="hoson"
                            label="Second Level Supervisor Name"
                            name="hoson"
                            autoComplete="hoson"
                            defaultValue={e.hos}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            size="small"
                            id="hose"
                            label="Second Level Supervisor Email"
                            name="hose"
                            autoComplete="hose"
                            defaultValue={e.hosemal}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            size="small"
                            id="reas"
                            label="Reason"
                            name="reas"
                            autoComplete="reas"
                          />
                        </Grid>
                        {!RPTDis && (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              variant="standard"
                              margin="normal"
                              required
                              fullWidth
                              type="file"
                              size="small"
                              id="rpt"
                              helperText={FileHelperText}
                              name="rpt"
                              onChange={startUpload}
                            />
                            {Uploading && (
                              <Box sx={{ textAlign: "center", my: 2 }}>
                                {UploadResp}
                              </Box>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Grid>
                {FormStep === 2 && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ textAlign: "right" }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      sx={{ mt: 3, mb: 2 }}
                      size="small"
                    >
                      <SendTwoToneIcon fontSize="small" sx={{ mr: 1 }} />
                      Send Request
                    </Button>

                    <Button
                      type="button"
                      variant="contained"
                      color="error"
                      size="small"
                      sx={{ mt: 3, mb: 2, ml: 2 }}
                      onClick={CloseDialog}
                    >
                      Close Form
                    </Button>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>
        ) : (
          <Box sx={{ py: 5, textAlign: "center" }}>
            <GridLoader />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
